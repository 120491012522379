<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <router-link class="back-link router-link-active" to="/config">
            <svg data-v-231527a2="" fill="none" height="12" viewBox="0 0 8 12" width="8"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z" data-v-231527a2="" fill="#0033A0"></path>
            </svg>
            назад
          </router-link>

          <h1>Настройка подсистемы «Анкетирование»</h1>

          <table class="config-list-table">
            <thead>
            <tr>
              <th>Название</th>
              <th>Дата создания</th>
              <th>Дата изменения</th>
              <th>Статус</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="config in configs" :key="config.id+'sdjjf'">
              <td style="max-width:400px">{{ config.anket.title }}</td>
              <td>{{ config.createdDate | dateFormat }}</td>
              <td>{{ config.modifiedDate | dateFormat }}</td>
              <td v-if="config.active" style="color: darkgreen;">
                Действующая<br>
                <a href="#" @click.stop="setActive(config.id, false)">Сделать недействующей</a>
              </td>
              <td v-else>
                Не действующая<br>
                <a href="#" @click.stop="setActive(config.id, true)">Сделать действующей</a>
              </td>
              <td>
                <div class="edit" @click="openAnket(config.id)">
                  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17 18C17.6 18 18 18.4 18 19C18 19.6 17.6 20 17 20C16.4 20 16 19.6 16 19C16 18.4 16.4 18 17 18ZM17 15C14.3 15 11.9 16.7 11 19C11.9 21.3 14.3 23 17 23C19.7 23 22.1 21.3 23 19C22.1 16.7 19.7 15 17 15ZM17 21.5C15.6 21.5 14.5 20.4 14.5 19C14.5 17.6 15.6 16.5 17 16.5C18.4 16.5 19.5 17.6 19.5 19C19.5 20.4 18.4 21.5 17 21.5ZM9.1 19.7L8.8 19H4V8H20V13.6C20.7 13.9 21.4 14.2 22 14.7V8C22 7.5 21.8 7 21.4 6.6C21 6.2 20.6 6 20 6H16V4C16 3.4 15.8 3 15.4 2.6C15 2.2 14.6 2 14 2H10C9.4 2 9 2.2 8.6 2.6C8.2 3 8 3.4 8 4V6H4C3.4 6 3 6.2 2.6 6.6C2.2 7 2 7.5 2 8V19C2 19.5 2.2 20 2.6 20.4C3 20.8 3.4 21 4 21H9.8C9.5 20.6 9.3 20.2 9.1 19.7ZM10 4H14V6H10V4Z"
                        fill="#0033A0"/>
                  </svg>
                </div>
                <div class="edit">
                  <router-link :to="'/config/anketsConfigEdit/'+config.id">
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M18.13 12L19.39 10.74C19.83 10.3 20.39 10.06 21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H11V19.13L11.13 19H5V5H12V12H18.13ZM14 4.5L19.5 10H14V4.5ZM19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83ZM22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19Z"
                          fill="#0033A0"/>
                    </svg>
                  </router-link>
                </div>
              </td>

            </tr>
            </tbody>
          </table>

          <div class="save-buttons">
            <v-btn color="primary" @click="$router.push('/config/anketsConfigEdit/')">Создать конфигурацию</v-btn>
          </div>

        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  name: "AnketsConfig",
  props: {},
  components: {},
  data() {
    return {
      configs: [],
      apiLoaded: false,
      errorText: null,
      apiFreeze: false //уже ушел запрос апи - нужно дождаться его окончания, прежде чем слать новые запросы
    };
  },
  methods: {
    async setActive(configId, active) {
      if (this.apiFreeze) {
        return
      }
      this.apiFreeze = true
      let req = await this.$postFormDataApi("/ankets/setActiveConfig?", {id: configId, active: active})
      if (req.ok) {
        await this.updateData()
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.apiFreeze = false
    },

    async updateData() {
      let req = await this.$getApi("/ankets/getConfigsList")
      if (req.ok) {
        this.configs = req.payload
        this.sortConfigsById()
        for (let c of this.configs) {
          c.anket = JSON.parse(c.anketJson)
        }
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    openAnket(configId){
      window.open("https://new.profczn.ru/"+configId, '_blank').focus();
    },

    sortConfigsById() {
      this.configs.sort((a, b) => (a.id > b.id) ? 1 : -1)
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    await this.updateData()
  }
};
</script>
